<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <view-title />
      </v-flex>
      <v-flex xs8 md5 v-if="sites.length > 0">
        <v-select
          :items="sites"
          label="Select a site"
          solo
          v-model="active_site"
          @change="selectSite"
        ></v-select>
      </v-flex>
      <v-flex xs-4 style="position: relative;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn outline class="svg-videocam" v-on="on" @click="toggleDialog">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Training Video</span>
        </v-tooltip>
        <video-player :dialog.sync="videoDialog" :videoID="videoID" />
      </v-flex>
    </v-layout>
    <loading-wrapper :loading="loading_site" mt="20vh">
      <template v-if="sites.length > 0">
        <v-layout wrap v-if="activeBrands.length > 0">
          <v-flex xs12 sm12 md6 lg6 v-for="brand in activeBrands" :key="brand.name">
            <v-card @click="goLocal(brand)" class="clickable">
              <v-img :src="brand.image" aspect-ratio="2.75" v-if="brand.image"></v-img>
              <v-img :src="require('./placeholder.png')" aspect-ratio="2.75" v-else></v-img>
              <v-card-title>
                <div>
                  <span class="H6-Selected-On-Surface-Medium-Emphasis-Left">{{ brand.name }}</span>
                </div>
              </v-card-title>
              <menu-status-chip :menu-id="getBrandMenuId(brand)"></menu-status-chip>
            </v-card>
          </v-flex>
        </v-layout>
        <construction message="No menus were set up for this site" v-else />
      </template>
      <construction message="This account has no accessible sites" v-else />
    </loading-wrapper>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MenuStatusChip from '@/components/menu-status-chip';
import { VimeoVideoID } from '@/constants';
import videoPlayer from '@/components/videoPlayerModal';

export default {
  components: {
    'menu-status-chip': MenuStatusChip,
    videoPlayer,
  },
  data: () => ({
    active_site: '',
    companyMenusPromises: {},
    brandMenus: [],
    globalMenus: [],
    loading_site: false,
    sitesCache: {},
    videoDialog: false,
  }),
  computed: {
    ...mapState('sites', ['siteMap']),
    ...mapGetters('sites', {
      isDHSite: 'isDHSite',
    }),
    sites() {
      if (this.siteMap) {
        return Object.values(this.siteMap)
          .filter((site) => !this.isDHSite(site))
          .map((site) => ({ text: site.name, value: site.id }));
      }
      return [];
    },
    activeBrands() {
      if (!this.active_site || !this.sitesCache[this.active_site]) return [];
      return Object.values(this.sitesCache[this.active_site])
        .filter((e) => e.linked)
        .map((e) => ({ image: e.style.logo, ...e }));
    },
    videoID() {
      return VimeoVideoID.localMenu;
    },
  },
  methods: {
    ...mapActions('sites', ['fetchAll', 'getLocationBrand']),
    ...mapActions('menus', ['fetchCompanyMenu']),
    ...mapActions('users', ['fetchUser']),
    toggleDialog() {
      this.videoDialog = !this.videoDialog;
    },
    async selectSite(e) {
      this.loading_site = true;
      const brands = await this.fetchBrands(e);
      this.$set(this.sitesCache, e, brands);
      this.loading_site = false;
    },
    async fetchBrands(siteid) {
      const site = this.siteMap[siteid];
      if (site) {
        this.brandMenus = [];
        this.globalMenus = [];
        this.companyMenusPromises = {};
        const requests = await Promise.all(
          Object.keys(site.brands).map((brandid) => this.fetchBrand(brandid)),
        );
        const brands = {};
        requests.forEach((data) => {
          brands[data.id] = data;
        });
        return brands;
      }
      return {};
    },
    async fetchBrand(brandId) {
      const brand = await this.getLocationBrand({ id: brandId });
      const linkedStatus = await this.determinedLinked(brand);
      brand.linked = linkedStatus;
      return brand;
    },
    async determinedLinked(brand) {
      if (!this.companyMenusPromises[brand.company]) {
        this.companyMenusPromises[brand.company] = this.fetchCompanyMenu({
          id: brand.company,
          nocache: true,
        });
      }
      const { menus } = await this.companyMenusPromises[brand.company];
      const menuUsersSet = new Set();
      menus.forEach((menu) => {
        if (menu.location_brand === brand.id) {
          this.brandMenus.push(menu);
        }
        if (!menu.location_brand) {
          this.globalMenus.push(menu);
        }

        if (menu.meta) {
          if (menu.meta.last_modified_user) menuUsersSet.add(menu.meta.last_modified_user);
          if (menu.meta.locked_by_user) menuUsersSet.add(menu.meta.locked_by_user);
        }
      });

      try {
        // Get user data for menu status chip component
        await Promise.all(
          Array.from(menuUsersSet).map((id) => this.fetchUser({ id, fields: ['id', 'name'] })),
        );
      } catch (err) {
        // Not critical data
      }

      if (this.globalMenus.length === 0) {
        return false;
      }
      return this.brandMenus.filter((m) => m.location_brand === brand.id).length >= 1;
    },
    goLocal(brand) {
      this.$router.push({
        name: 'menu-local',
        params: {
          brand_id: brand.id,
          company_id: brand.company,
          sector_id: brand.sector,
        },
      });
    },
    getBrandMenuId(brand) {
      const brandMenus = this.brandMenus.filter((m) => m.location_brand === brand.id);
      const menu = brandMenus.find(
        (m) => m.meta && (m.meta.locked_by_user || m.meta.last_modified_user),
      );
      return (menu && menu.id) || (brandMenus[0] && brandMenus[0].id);
    },
  },
  async mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Your Menus',
      to: { name: 'menu-site-listing' },
    });
  },
  watch: {
    sites: {
      async handler(newValue) {
        if (!newValue || !newValue.length) return;
        this.$store.commit('adminPanel/setLoading', true);
        if (this.sites.length > 0) {
          await this.selectSite(newValue[0].value);
          this.active_site = newValue[0].value;
        }
        this.$store.commit('adminPanel/setLoading', false);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.svg-videocam {
  width: 30px;
  height: 30px;
  display: inline;
  border: none !important;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
