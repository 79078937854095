import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, { attrs: { xs12: "" } }, [_c("view-title")], 1),
          _vm.sites.length > 0
            ? _c(
                VFlex,
                { attrs: { xs8: "", md5: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.sites,
                      label: "Select a site",
                      solo: ""
                    },
                    on: { change: _vm.selectSite },
                    model: {
                      value: _vm.active_site,
                      callback: function($$v) {
                        _vm.active_site = $$v
                      },
                      expression: "active_site"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            VFlex,
            { staticStyle: { position: "relative" }, attrs: { "xs-4": "" } },
            [
              _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                staticClass: "svg-videocam",
                                attrs: { outline: "" },
                                on: { click: _vm.toggleDialog }
                              },
                              on
                            ),
                            [_c(VIcon, [_vm._v("mdi-help-circle")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Training Video")])]
              ),
              _c("video-player", {
                attrs: { dialog: _vm.videoDialog, videoID: _vm.videoID },
                on: {
                  "update:dialog": function($event) {
                    _vm.videoDialog = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "loading-wrapper",
        { attrs: { loading: _vm.loading_site, mt: "20vh" } },
        [
          _vm.sites.length > 0
            ? [
                _vm.activeBrands.length > 0
                  ? _c(
                      VLayout,
                      { attrs: { wrap: "" } },
                      _vm._l(_vm.activeBrands, function(brand) {
                        return _c(
                          VFlex,
                          {
                            key: brand.name,
                            attrs: { xs12: "", sm12: "", md6: "", lg6: "" }
                          },
                          [
                            _c(
                              VCard,
                              {
                                staticClass: "clickable",
                                on: {
                                  click: function($event) {
                                    return _vm.goLocal(brand)
                                  }
                                }
                              },
                              [
                                brand.image
                                  ? _c(VImg, {
                                      attrs: {
                                        src: brand.image,
                                        "aspect-ratio": "2.75"
                                      }
                                    })
                                  : _c(VImg, {
                                      attrs: {
                                        src: require("./placeholder.png"),
                                        "aspect-ratio": "2.75"
                                      }
                                    }),
                                _c(VCardTitle, [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "H6-Selected-On-Surface-Medium-Emphasis-Left"
                                      },
                                      [_vm._v(_vm._s(brand.name))]
                                    )
                                  ])
                                ]),
                                _c("menu-status-chip", {
                                  attrs: {
                                    "menu-id": _vm.getBrandMenuId(brand)
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _c("construction", {
                      attrs: { message: "No menus were set up for this site" }
                    })
              ]
            : _c("construction", {
                attrs: { message: "This account has no accessible sites" }
              })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }